:root {
  --general-padding: 2rem;
  --general-border-radius: 1rem;
  --primary: #302783;
}
body {
  padding: var(--general-padding);
}

p { white-space: pre-line; margin: 0 }
h4 { white-space: pre-line; margin: 0 }

.grid {
  display: grid;
  gap: var(--general-padding);
}

.fl-1 {
  flex: 1
}

.pointer { cursor: pointer; transition-duration: 300ms; }

td { white-space: pre-line; }
th, tr, td {
  text-transform: none!important;
}

.title {
  max-height: 2.4rem;
  flex: 1;
  overflow: hidden;
}

.text-1-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.text-2-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.appbar > div > h2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1; 
  -webkit-box-orient: vertical;
}

.abs-fill {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.btn:focus { outline: none; box-shadow: none; }

.grid-2 { grid-template-columns: repeat(2, 1fr) }
.grid-3 { grid-template-columns: repeat(3, 1fr) }
.grid-4 { grid-template-columns: repeat(4, 1fr) }
.grid-6 { grid-template-columns: repeat(6, 1fr) }

.grid-slim { gap: 0.5rem!important }

.grid-search-event {grid-template-columns: 5fr 2fr 1fr}

.grid {
  display: grid;
  gap: calc(var(--general-padding));
}

.app-wrapper { display: flex; flex-direction: row; }
.app-content { flex: 1; margin-left: var(--general-padding); position: relative; height: fit-content; }

nav, .main, .sidebar {
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  padding: 1rem;
  background-color: #fff;
}

form {
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  padding: 1rem;
  background-color: #fff;
  display: grid;
  gap: 1rem;
}
form > .grid { gap: 1rem; }
form > .grid > .grid { gap: 1rem }
form > details > .grid { gap: 1rem }
form > .grid > .grid > .grid { gap: 1rem }
form > .grid > .grid > .grid > .grid { gap: 1rem }

.appbar {
  /* position: -webkit-sticky; */
  /* position: sticky; */
  /* top: var(--general-padding); */
  display: flex;
  flex-direction: row;
  align-items: center;
  /* z-index: 1; */
}

td > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 64px;
}


.async-entity-dropdown {
  display: inline-block;
  position: absolute;
  width: 75%;
  max-height: 12rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dropdown-menu-item:not(:last-child) {
  border-bottom: 1px solid #DADADA;
}

.dropdown-menu-item {
  padding: 0.5rem
}
.dropdown-menu-item:hover {
  background-color: gray;
}

.dropdown-menu-item > a {
  color: black;
  text-decoration: none;
}

.dropdown-menu {
  padding: 0;

}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 15rem;
  height: calc(100vh - var(--general-padding) * 2);
  position: -webkit-sticky;
  position: sticky;
  top: var(--general-padding);
}

.center { 
  left: 50%; top: 50%; 
  transform: translate(-50%, -50%)
}

.sidebar > .menu {
  flex: 1;
  overflow-y: scroll;
}



tr:last-child {
  border-bottom: none!important;
}

table.inline {
  box-shadow: none;
  padding: 0;
}

table > thead {
  vertical-align: bottom;
}

th,tr,td {
  padding: 1rem 1rem;
  text-transform: capitalize;
  letter-spacing: 0;
  border-bottom: 1px solid #e9ecef!important;
  border-top: 1px solid #e9ecef!important;
}

.table td, .table th:last-child {
  border-bottom: none!important;

}

.engine {
  overflow: hidden;
  position: relative;
}

.overlay {
  position: absolute;
  width: 150%;
  height: 150%;
  background-color: #00000055;
  z-index: 1;
}

.modal-overlay {
  background-color: #000000aa;
  position: absolute;
}

.button-like {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
}

.btn-primary {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}
.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}
.btn-outline-primary:hover {
  background-color: var(--primary)!important;
  border-color: var(--primary)!important;
}

.event-tile-image {
  object-fit: cover;
  aspect-ratio: 1/1;
}

.event-tile-wrapper > div {
  position: absolute;
  background-color: #454545DA;
  color: white!important;
  width: 100%;
  bottom: 0;
}

.mobile-only { display: none }

.dashboard-table {
  grid-column: 2/5;
  grid-row: 1
}

.info {
  position: relative;
}

.info:hover .info-text {
  display:block;
}

.info-text {
  display: none;
  color: white;
  background-color: #017bfe;
  font-weight: normal;
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 300px;
  text-align: center;
  left: 50%; 
  top: 50%; 
  transform: translate(-50%, calc(-100% - 1rem));
  position: absolute;
  z-index: 1000;
}

.info-text::after{
  content: '';
  position: absolute;
  left: 50%;
  top: calc(100% - 0.125rem);
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #017bfe;
  clear: both;
}

.seating-plan {
  width: 100%;
  border: none;
  min-height: 66vh;
}

.seating-menu {
  padding: 1rem;
  position: absolute;
  display: flex;
  flex-direction: column;
}

.seating-engine-appbar {
  border-bottom-left-radius: 0!important;
  border-bottom-right-radius: 0!important;
  height: 4rem!important;
}

.seating-engine {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: #000000aa;
}

.seating-engine > section {
  height: calc(100vh - 4rem);
  width: calc(100vw - 4rem);
}

.promoted-event-dialog {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 100vh;
  width: 100vw;
  background-color: #000000aa;
}

.promoted-event-window {
  position: relative;
  height: 75vh!important;
  aspect-ratio: 9/16;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  overflow: hidden;
}

.promoted-event-background {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(0.5rem);
  transform: scale(1.1);
}

.promoted-event-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.dropBox {
  width: 100%!important;
}

.new-event-card {
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 5px 14px;
  border-radius: var(--general-border-radius);
  background-color: #fff;
  background-size: 100%;
}

.left-pad-sidebar {
  /* Half of sidebar */
  margin-left: 7.5rem;
}

.min-height {
  min-height: 200px;
}

/* Tablets */
@media only screen and (max-width: 768px) {
  body { padding: calc(var(--general-padding)/2) }

  .fw-100-m { width: calc(100vw - var(--general-padding)); }

  .mini-events {
    display: flex;
    width: calc(100vw - var(--general-padding));
    overflow-x: scroll;
  }

  .mini-events > div {
    margin-right: 1rem;
  }
  .mini-events > div:last-child {
    margin-right: 0;
  }

  .dashboard-table {
    height: 20rem;
  }

  .mobile-only { display: unset }

  .grid-2 { grid-template-columns: repeat(1, 1fr) }
  .grid-3 { grid-template-columns: repeat(3, 1fr) }
  .grid-4 { grid-template-columns: repeat(2, 1fr) }
  .grid-6 { grid-template-columns: repeat(3, 1fr) }

  .grid-1-m { grid-template-columns: repeat(1, 1fr)!important; }

  .dashboard-table {
    grid-column: 1/1;
    grid-row: 2
  }

  .sidebar {
    position: absolute;
    top: 6.5rem;
    right: -100%;
    z-index: 1;
    width: calc(((100vw - 2rem)));
    height: calc(100vh - 7.5rem);
    transition-duration: 400ms;
  }

  .sidebar-show {
    right: 1rem;
  }

  .app-content {
    margin: 0;
  }

  .grid { gap: calc(var(--general-padding) / 2) }

  .appbar {
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--general-padding) / 2);
    z-index: 999;
  }
  .left-pad-sidebar {
    margin-left:0 ;
  }
}

.map {
  position: relative;
  border-radius: 0.25rem;
  overflow: hidden;
}

.map > iframe {
  position: absolute;
  width: 250%;
  height: 200%;
}

label {
  font-weight: bold;
}

/* RTG */

.rtg__container{
  --primary-color:#0b708b;
  --wating-color:#c00021;
  --pin-color:#0fa9d0;
  --arrow-size:15px
}
.rtg__container * {
  box-sizing:border-box
}
.rtg__modal{
  background:#fff;
  -webkit-filter:drop-shadow(0 0 5px rgba(0,0,0,.5));
  left:50%;
  max-width:90vw;
  min-width:300px;
  padding:40px 20px 20px;
  position:absolute;
  top:50vh;
  transition-duration:.8s;
  transition-property:top,left,scale;
  border-radius: 0;
  filter: none;
  border: 1px solid black;
  z-index: 1!important;
}
.rtg__modal--hidden{
  display:none;
  -webkit-transform:translate(-50%,-50%) scale(0);
  transform:translate(-50%,-50%) scale(0)
}
.rtg__modal--visible{
  display:block;
  opacity:1;
  -webkit-transform:translate(-50%,-50%) scale(1);
  transform:translate(-50%,-50%) scale(1)
}
.rtg__modal:after{
  background:inherit;
  content:"";
  position:absolute;
  z-index:-1
}
.rtg__modal--top-center:after{
  bottom:calc(-1 * var(--arrow-size) + 1px);
  -webkit-clip-path:polygon(0 0,50% 100%,100% 0);
  clip-path:polygon(0 0,50% 100%,100% 0);
  display:block;
  height:var(--arrow-size);
  left:50%;
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width:calc(2 * var(--arrow-size));
  background: black;
  bottom: -15px;
}
.rtg__modal--top-right:after{
  bottom:calc(-1 * var(--arrow-size));
  -webkit-clip-path:polygon(0 100%,50% 0,100% 50%);
  clip-path:polygon(0 100%,50% 0,100% 50%);
  display:block;
  height:calc(2 * var(--arrow-size));
  left:calc(-1 * var(--arrow-size));
  width:calc(2 * var(--arrow-size))
}
.rtg__modal--top-left:after{
  bottom:calc(-1 * var(--arrow-size));
  -webkit-clip-path:polygon(50% 0,100% 100%,0 50%);
  clip-path:polygon(50% 0,100% 100%,0 50%);
  display:block;
  height:calc(2 * var(--arrow-size));
  right:calc(-1 * var(--arrow-size));
  width:calc(2 * var(--arrow-size))
}
.rtg__modal--bottom-center:after{
  -webkit-clip-path:polygon(0 100%,50% 0,100% 100%);
  clip-path:polygon(0 100%,50% 0,100% 100%);
  display:block;
  height:var(--arrow-size);
  left:50%;
  top:calc(-1 * var(--arrow-size) + 1px);
  -webkit-transform:translateX(-50%);
  transform:translateX(-50%);
  width:calc(2 * var(--arrow-size))
}
.rtg__modal--center-right:after{
  -webkit-clip-path:polygon(100% 0,0 50%,100% 100%);
  clip-path:polygon(100% 0,0 50%,100% 100%);
  display:block;
  height:calc(2 * var(--arrow-size) * 2px);
  left:calc(-1 * var(--arrow-size) + 1px);
  top:50%;
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
  width:var(--arrow-size)
}
.rtg__modal--center-left:after{
  -webkit-clip-path:polygon(0 0,100% 50%,0 100%);
  clip-path:polygon(0 0,100% 50%,0 100%);
  display:block;
  height:calc(2 * var(--arrow-size));
  right:calc(-1 * var(--arrow-size) + 1px);
  top:50%;
  -webkit-transform:translateY(-50%);
  transform:translateY(-50%);
  width:var(--arrow-size)
}
.rtg__modal--bottom-left:after{
  -webkit-clip-path:polygon(0 50%,100% 0,50% 100%);
  clip-path:polygon(0 50%,100% 0,50% 100%);
  display:block;
  height:calc(2 * var(--arrow-size));
  right:calc(-1 * var(--arrow-size));
  top:calc(-1 * var(--arrow-size));
  width:calc(2 * var(--arrow-size))
}
.rtg__modal--bottom-right:after{
  -webkit-clip-path:polygon(0 0,50% 100%,100% 50%);
  clip-path:polygon(0 0,50% 100%,100% 50%);
  display:block;
  height:calc(2 * var(--arrow-size));
  right:calc(-1 * var(--arrow-size));
  top:calc(-1 * var(--arrow-size));
  width:calc(2 * var(--arrow-size))
}
body{ position:relative }
.rtg__backdrop{
  border-radius:5px;
  box-shadow:0 0 0 1000vh rgba(0,0,0,.5);
  pointer-events:none;
  position:absolute
}
.rtg__backdrop--visible{
  display:block
}
.rtg__backdrop--hidden{
  display:none
}
body{
  counter-reset:rtg_selector
}
.rtg__control{
  -webkit-align-self:flex-end;
  align-self:flex-end;
  display:-webkit-flex;
  display:flex;
  margin-top:24px
}
.rtg__button,.rtg__control{
  -webkit-justify-content:center;
  justify-content:center
}
.rtg__button{
  -webkit-align-items:center;
  align-items:center;
  border:none;
  color:var(--primary-color);
  display:-webkit-inline-flex;
  display:inline-flex;
  margin:0 2px;
  outline:none;
  padding:0
}
.rtg__button:focus{
  outline:1px solid rgba(0,0,0,.1)
}
.rtg__button:disabled{
  cursor:not-allowed
}
.rtg__button svg{
  height:30px;
  width:30px
}
.rtg__button--prev svg{
  -webkit-transform:rotate(180deg);
  transform:rotate(180deg)
}
.rtg__button--selector{
  position:relative
}
.rtg__button--selector svg{
  height:20px;
  width:20px
}
.rtg__button--selector:before{
  -webkit-align-items:center;
  align-items:center;
  bottom:28px;
  content:counter(rtg_selector);
  counter-increment:rtg_selector;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
  justify-content:center;
  opacity:0;
  position:absolute;
  width:20px
}
.rtg__button--selector:hover:before{
  opacity:.5
}
.rtg__button--hide-before:before{
  display:none
}
.rtg__badge{
  background:var(--primary-color);
  border-radius:4px;
  color:#fff;
  display:-webkit-flex;
  display:flex;
  -webkit-justify-content:center;
  justify-content:center;
  left:-6px;
  min-width:60px;
  padding:2px 6px;
  position:absolute;
  top:-6px
}
.rtg__badge span{
  font-size:.9rem
}
.rtg__badge--waiting{
  -webkit-animation:blinking;
  animation:blinking;
  animation-direction:reverse;
  -webkit-animation-duration:1.5s;
  animation-duration:1.5s;
  -webkit-animation-iteration-count:infinite;
  animation-iteration-count:infinite;
  -webkit-animation-timing-function:ease-in-out;
  animation-timing-function:ease-in-out
}
@-webkit-keyframes blinking{
  0%{
      background:var(--primary-color)
  }
  to{
      background:var(--wating-color)
  }
}
@keyframes blinking{
  0%{
      background:var(--primary-color)
  }
  to{
      background:var(--wating-color)
  }
}
.rtg__pin{
  border:none;
  border-radius:50%;
  height:20px;
  left:-100px;
  margin:0;
  padding:0;
  position:absolute;
  top:-100px;
  -webkit-transform:translate(-50%,-50%);
  transform:translate(-50%,-50%);
  width:20px
}
.rtg__pin,.rtg__pin:focus{
  outline:none
}
.rtg__pin:hover span{
  display:block
}
.rtg__pin span{
  background:#fff;
  border:1px solid rgba(0,0,0,.3);
  border-radius:2px;
  color:var(--primary-color);
  display:none;
  left:10px;
  padding:4px 6px;
  position:absolute;
  top:-40px;
  white-space:nowrap
}
.rtg__pin:before{
  -webkit-animation:pulse-ring 1.25s cubic-bezier(.215,.61,.355,1) infinite;
  animation:pulse-ring 1.25s cubic-bezier(.215,.61,.355,1) infinite;
  background-color:var(--pin-color);
  border-radius:45px;
  box-sizing:border-box;
  content:"";
  display:block;
  height:300%;
  margin-left:-100%;
  margin-top:-100%;
  position:relative;
  width:300%
}
.rtg__pin:after{
  -webkit-animation:pulse-dot 1.25s cubic-bezier(.455,.03,.515,.955) -.4s infinite;
  animation:pulse-dot 1.25s cubic-bezier(.455,.03,.515,.955) -.4s infinite;
  background-color:#fff;
  border-radius:15px;
  box-shadow:0 0 8px rgba(0,0,0,.3);
  content:"";
  display:block;
  height:100%;
  left:0;
  position:absolute;
  top:0;
  width:100%
}
@-webkit-keyframes pulse-ring{
  0%{
      -webkit-transform:scale(.33);
      transform:scale(.33)
  }
  80%,to{
      opacity:0
  }
}
@keyframes pulse-ring{
  0%{
      -webkit-transform:scale(.33);
      transform:scale(.33)
  }
  80%,to{
      opacity:0
  }
}
@-webkit-keyframes pulse-dot{
  0%{
      -webkit-transform:scale(.8);
      transform:scale(.8)
  }
  50%{
      -webkit-transform:scale(1);
      transform:scale(1)
  }
  to{
      -webkit-transform:scale(.8);
      transform:scale(.8)
  }
}
@keyframes pulse-dot{
  0%{
      -webkit-transform:scale(.8);
      transform:scale(.8)
  }
  50%{
      -webkit-transform:scale(1);
      transform:scale(1)
  }
  to{
      -webkit-transform:scale(.8);
      transform:scale(.8)
  }
}
.rtg__button--close{
  color:var(--primary-color);
  position:absolute;
  right:10px;
  top:10px
}
.rtg__button--close svg{
  height:25px;
  width:25px
}
